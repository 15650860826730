import { useCallback, useEffect, useMemo, useState } from 'react'
import useChannel from './useChannel'
import useDevice from 'hooks/useDevice'

const defaultOptions = {
  enableHighAccuracy: false,
  maximumAge: 0,
  timeout: Infinity,
}

export type Position = GeolocationPosition | undefined

export const useGeolocation = (options?: typeof defaultOptions) => {
  const [error, setError] = useState('')
  const [position, setPosition] = useState<Position>()

  const { latitude, longitude } = useMemo(() => {
    return position?.coords as GeolocationCoordinates || {}
  }, [position])

  const geolocationIsGood = useMemo(() => {
    return (latitude && !isNaN(latitude)) && (longitude && !isNaN(longitude))
  }, [latitude, longitude])

  const { nativeGeolocation, transmitGeoRequest } = useChannel()
  const device = useDevice()

  useEffect(() => {
    if (nativeGeolocation) {
      setPosition(nativeGeolocation)
    }
  }, [nativeGeolocation])

  const updatePosition = useCallback((position) => {
    setPosition((prevPosition) => {
      if (!position.coords.latitude || !position.coords.longitude) {
        return prevPosition
      }

      // set position only once per application load
      if (!prevPosition) {
        return position
      }
    })
  }, [])

  const getGeolocation = useCallback(() => {
    if (device.isNativeApp) {
      transmitGeoRequest()
    } else if (device.isBrowser) {
      const onError = (geolocationError: GeolocationPositionError) => {
        if (geolocationError.message !== error) {
          setError(geolocationError.message)
        }
      }

      const onSuccess = (geolocationPosition: GeolocationPosition) => {
        updatePosition(geolocationPosition)
      }

      return window.navigator.geolocation.getCurrentPosition(onSuccess, onError, { ...defaultOptions, ...options })
    }
  }, [device.isBrowser, device.isNativeApp, error, options, transmitGeoRequest, updatePosition])

  return { error, geolocationIsGood, getGeolocation, latitude, longitude, position }
}
