import requestIp from 'request-ip'
import Screen from 'screens/explore/stores'
import type { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'
import { api } from 'config/apiClient'
import { sortStoreList } from 'utils/store'

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const cookie = context.req.headers.cookie
  const ip = requestIp.getClientIp(context.req) || ''
  const response = await api('getStoresByDistance', { ip }, cookie)
  return {
    props: {
      stores: response.data
    }
  }
}

const Stores = (props: InferGetServerSidePropsType<typeof getServerSideProps>) => (
  <Screen stores={sortStoreList(props.stores)} />
)

export default Stores
