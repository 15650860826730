import { Offer } from './user'

export interface Order {
  orderId: string
  storeName: string
  storePhoto: string | null
  ts: string
  status: string
  total: number
  items: number
  points?: number
}

export interface GetOrderDetailsRequest {
  userId: string
  orderId: string
}

export interface GetOrderDetailsResponse {
  orderId: string
  image: string | null
  storeId: string
  storeName: string
  storeAddress: string
  ts: string
  status: OrderStatus
  items: OrderItem[]
  point: number
  pointDetails: {
    order: number
    offer: number
  }
  fees: OrderFees
  rating: number | null
  orderAgain?: boolean
}

export interface OrderFeedbackRequest {
  orderId: string
  feedbackOrderReady: number
  feedbackOrderCorrect: number
  rating: number
  message?: string
}

export interface OrderFeedbackResponse {
  result: boolean
}

export interface OrderFees {
  salesTax: number
  tip: number
  service: number
  donation?: number
}

export interface OrderItem {
  name: string
  total: number
  rewardDiscount: number
  options: string[]
  note: [{ type: string, value: string }]
}

export interface GetUserCurrentOrderDetailsRequest {
  orderId: string
  overrideDay?: string
}

export interface GetUserActiveOrdersRequest {
  userId: string
}

export interface GetOrderStatusRequest {
  orderId: string
}

export interface GetOrderStatusResponse {
  status: string
}

// TODO: REmove this
export enum OrderStatus {
  NEW = 'new',
  ACCEPTED = 'accepted',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  REJECTED = 'rejected',
}

// WHEN "order"."orderStatus" = 'new' THEN 'Sent'
// WHEN "order"."orderStatus" = 'accepted' THEN 'In Progress'
// WHEN "order"."orderStatus" = 'rejected' THEN 'Rejected'
// WHEN "order"."orderStatus" = 'completed' THEN 'Ready'
// ELSE 'Cancelled'

export interface GetUserCurrentOrderDetailsResponse {
  cancelledMessage: string | null
  curbSide: boolean
  id: string
  itemsCount: number
  orderStatus: string
  points: number | null
  rating: number | null
  showHereCta: boolean
  statusReason: string
  storeAddress: string
  storeId: string
  storeName: string
  total: number
  ts: string
  waitTime: string | null
  photo?: string | null
  instruction?: string
  offer: Offer[]
  deliveryType: OrderDeliveryType
}

export interface GetUserActiveOrdersResponse {
  activeOrdersCount: number
}

export type GetUserOrderListResponse = Order[]

export interface UpdateOrderArrivalRequest {
  orderId: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UpdateOrderArrivalResponse {}

export interface CreateOrderItem {
  id: string
  size: string
  note: string
  options: { id: string, modId: string, quantity: number }[]
}
export interface CreateOrderRequest {
  customerVehicleDescription?: string
  deliveryType?: OrderDeliveryType
  idempotencyKey: string
  items: CreateOrderItem[]
  paymentMethodId?: string
  paymentMethodType: string
  pickupDelayMinutes?: number
  promoCodes?: string[]
  reward?: number
  storeId: string
  tip?: number
  source: 'app' | 'mobile-web' | 'desktop'
  donation?: boolean
}

export interface CreateOrderResponse {
  orderId: string
}

export type GetOrderPricingRequest = Omit<
  CreateOrderRequest,
  | 'deliveryType'
  | 'idempotencyKey'
  | 'pickupDelayMinutes'
  | 'customerVehicleDescription'
  | 'tip'
  | 'source'
  | ''
>

export interface GetOrderPricingResponse {
  prices: {
    appliedPromos?: {
      [itemId: string]: OrderPricingPromo
    }
    discountsTotal: string
    total: string
    itemsTotal: string
    itemsTaxableTotal: string
    pricesPerItem: OrderPricingItemPrices[]
    additionalCharges: OrderPricingAdditionalCharge[]
    additionalChargesTotal: string
    tipAmount: string
  }
  points: {
    totalItemPoints: number
    bonusPoints: number
    totalPoints: number
  }
  tipDisabledMessage: string | null
}

export interface OrderPricingAdditionalCharge {
  name: string
  category: string
  amount: string
  rate: string
  type: string
}

export interface OrderPricingItemPrices {
  id?: string // uuid
  isTaxable: boolean
  itemId: string
  itemTotal: string
  itemDiscount: string
  itemOptionsTotal: string
  itemPromoDiscount: string
  itemRewardDiscount: string
  itemBasePrice: string
  itemPoints: number
  itemSizeName: string
}

export interface OrderPricingPromo {
  code: string
  create: string
  deals: OrderPricingPromoDeal[]
  description: string
  endTimestamp: string | null
  id: string
  isDisabled: boolean
  type: string
  version: number
}

export interface OrderPricingPromoDeal {
  discountType: string
  discountValue: string
  maxValidItemPrice: string
  promoMenuCategoryNameConstraints: string[]
}

export enum OrderDeliveryType {
  Pickup = 'pickup',
  CarryOut = 'carry_out',
  DineIn = 'dine_in',
  DriveThru = 'drive_thru',
}
