import { isEmpty, uniqBy } from 'lodash'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'

import { api } from 'config/apiClient'
import { LocalStorageKeys } from 'config/localStorageKeys'
import { useGeolocation } from 'hooks/useGeolocation'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { StoreByDistance } from 'types/api/store'

import { sortStoreList } from 'utils/store'

const useStores = (defaultStores: StoreByDistance[]) => {
  const { geolocationIsGood, getGeolocation, latitude, longitude } = useGeolocation()
  const [locationServices] = useLocalStorage(LocalStorageKeys.LOCATION_SERVICES, 'false')
  const [stores, setStores] = useState(defaultStores)

  const router = useRouter()

  useEffect(() => {
    if (locationServices === 'true') {
      getGeolocation()
    } else {
      router.push({
        pathname: '/explore/location-permissions',
      })
    }
  }, [getGeolocation, locationServices, router])

  const updateStores = useCallback(async () => {
    if (geolocationIsGood) {
      const response = await api('getStoresByDistance', {
        lat: latitude,
        lon: longitude,
      })

      if (response.success) {
        setStores(sortStoreList(uniqBy([...response.data, ...defaultStores], 'id')))
      }
    }
  }, [defaultStores, geolocationIsGood, latitude, longitude, setStores])

  useEffect(() => {
    updateStores()
  }, [updateStores])

  return { stores: isEmpty(stores) ? defaultStores : stores, updateStores }
}

export default useStores
