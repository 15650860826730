import { LocalStorageKeys } from 'config/localStorageKeys'
import { StandardPage } from 'components/Layout'
import StoreList from 'components/pages/explore/stores/StoreList'
import { useLocalStorage } from 'hooks/useLocalStorage'
import useStores from 'hooks/useStores'

import { StoreByDistance } from 'types/api/store'
import StoreListHeader from 'components/pages/explore/stores/StoreListHeader'

type Props = {
  stores: StoreByDistance[]
}

const Stores = ({ stores: storesFromSSR }: Props) => {
  const [locationServices] = useLocalStorage(LocalStorageKeys.LOCATION_SERVICES, 'false')
  const { stores } = useStores(storesFromSSR)

  if (locationServices !== 'true') {
    return null
  }

  return (
    <StandardPage
      headerComponent={() => (
        <StoreListHeader header="Stores Near Me" />
      )}
      headerVariant="tall"
      overrideMainCSS={{ padding: '$s' }}
    >
      <StoreList stores={stores} showLike />
    </StandardPage>
  )
}

export default Stores
