import { add, isWithinInterval, startOfDay } from 'date-fns'
import { partition, reduce, sortBy, take, uniqBy } from 'lodash'

import { api } from 'config/apiClient'
import { FavoriteStore, StoresListResponse } from 'types/api'
import { OrderDeliveryType } from 'types/api/order'
import {
  GetStorePickUpOptionsResponse,
  PickupOption,
  StoreByDistance,
} from 'types/api/store'

const pickUpOptions = {
  pickUp: { name: 'On counter', value: OrderDeliveryType.Pickup },
  carryOut: { name: 'Curbside', value: OrderDeliveryType.CarryOut },
  dineIn: { name: 'Dine in', value: OrderDeliveryType.DineIn },
  driveThru: { name: 'Drive thru', value: OrderDeliveryType.DriveThru },
}

export const mapStoreDeliveryType = (data: GetStorePickUpOptionsResponse) => {
  return reduce(
    data,
    (result, value, key) => {
      const isOptionAvailable = value

      if (isOptionAvailable && key in pickUpOptions) {
        result.push(pickUpOptions[key as keyof typeof pickUpOptions])
      }

      return result
    },
    [] as PickupOption[]
  )
}

export const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber
    .replace(/[^0-9]/g, '')
    .slice(-10)
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
}

export const isStoreOpen = (store: {
  close: number
  open: number
  wait: number
  online: boolean
}) => {
  const now = new Date()
  const midnight = startOfDay(now)
  const close = store.close - (store.wait || 5)
  const start = add(midnight, { minutes: store.open })
  const end = add(midnight, { minutes: close })

  if (start >= end) {
    return false
  }

  const isAcceptingOrders =
    start && end && isWithinInterval(now, { start, end })

  return isAcceptingOrders && store.online
}

export const sortStoreList = (
  storeList: StoresListResponse
): StoresListResponse => {
  const [onlineStores, offlineStores] = partition(
    storeList,
    (store) => !!isStoreOpen(store)
  )

  const sortedOnlineStoresByDistance = sortBy(
    onlineStores,
    (store) => store.mi
  )

  const sortedOfflineStoresByDistance = sortBy(
    offlineStores,
    (store) => store.mi
  )

  return [...sortedOnlineStoresByDistance, ...sortedOfflineStoresByDistance]
}

export const StoreIdMapping: Record<string, string> = {
  // Maple Moose - GOOD STORE - cadb38f8-ad31-423e-8db9-2184f30edef5
  // Maple Moose drive thru - bad store - aae5efe7-4a3d-4607-89da-95507045eea0
  'aae5efe7-4a3d-4607-89da-95507045eea0':
    'cadb38f8-ad31-423e-8db9-2184f30edef5',
}

export const completeFavStoresWithNearbyStores = async (
  favoriteStores: FavoriteStore[],
  ip: string,
  cookie?: string
) => {
  const { data } = await api('getStoresByDistance', { ip }, cookie)

  const combinedStores = [
    ...favoriteStores,
    ...take(data, 5 - favoriteStores.length).map((store: StoreByDistance) => ({
      store_id: store.id,
      company_id: store.companyId,
      company_name: store.name,
      name: store.name,
      image: store.image,
      address: store.address,
      items: [],
      pos_store: store.posStore,
    })),
  ]

  return uniqBy(combinedStores, 'store_id')
}

export const isCurbsideOrDriveThru = (pickup: string) =>
  pickup === OrderDeliveryType.CarryOut ||
  pickup === OrderDeliveryType.DriveThru
